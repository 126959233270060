import { axios } from "@/utils/api/index";

/**
 * Get all recent prises by 100.
 * @param page Current page.
 * @return {Promise<AxiosResponse<any>>}
 */
export const recentPrises = page => {
  return axios().get("/prise/all", {
    params: { page: page }
  });
};

/**
 * Get one prise by ID.
 * @param id Desired prise ID (UUID).
 * @return {Promise<AxiosResponse<any>>}
 */
export const getOne = id => {
  return axios().get(`/prise/${id}`);
};

/**
 * Prise participation validation.
 * @param id Participation ID (UUID).
 * @param accepted Is participation are accepted or not.
 * @return {Promise<AxiosResponse<any>>}
 */
export const priseParticipationValidation = (id, accepted) => {
  return axios().put("/prise/participation-validation", { id, accepted });
};

/**
 * Update prise.
 * @param id Prise ID (UUID).
 * @param title Prise title.
 * @param intro Introduction.
 * @param start_at Date when prise starts.
 * @param end_at Date when prise ends.
 * @param program Program of prise day.
 * @param description Complete description of prise.
 * @param video_url YouTube URL inserted into iframe.
 * @param private_details Details only showed to confirmed participants.
 * @param quota Maximum number of participants.
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = (
  id,
  title,
  intro,
  start_at,
  end_at,
  program,
  description,
  video_url,
  private_details,
  quota
) => {
  return axios().patch("/prise", {
    id,
    title,
    intro,
    start_at,
    end_at,
    program,
    description,
    video_url,
    private_details,
    quota
  });
};

/**
 * Post prise.
 * @param title Prise title.
 * @param intro Introduction.
 * @param start_at Date when prise starts.
 * @param end_at Date when prise ends.
 * @param program Program of prise day.
 * @param description Complete description of prise.
 * @param video_url YouTube URL inserted into iframe.
 * @param private_details Details only showed to confirmed participants.
 * @param quota Maximum number of participants.
 * @return {Promise<AxiosResponse<any>>}
 */
export const postNew = (
  title,
  intro,
  start_at,
  end_at,
  program,
  description,
  video_url,
  private_details,
  quota
) => {
  return axios().post("/prise", {
    title,
    intro,
    start_at,
    end_at,
    program,
    description,
    video_url,
    private_details,
    quota
  });
};

/**
 * Update prise image.
 * @param id Related prise ID (UUID).
 * @param file New prise image.
 */
export const updateImage = (id, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return axios().put("/prise/image/" + id, formData);
};

/**
 * Get prise picture entire URL.
 * @param pictureKey Prise picture key taken from API response.
 */
export const getPrisePicture = pictureKey => {
  return pictureKey
    ? "https://art-prise.s3.eu-west-3.amazonaws.com/" + pictureKey
    : require("@/assets/default-prise.jpg");
};

/**
 * Post new speaker.
 * @param priseId Related prise ID (UUID).
 * @param name Speaker name.
 */
export const newSpeaker = (priseId, name) => {
  return axios().post("prise/new-speaker", {
    id: priseId,
    name: name
  });
};

/**
 * Update speaker name.
 * @param id Related speaker ID (UUID).
 * @param name Updated name.
 */
export const updateSpeaker = (id, name) => {
  return axios().put("prise/update-speaker", { id, name });
};

/**
 * Update speaker image.
 * @param id Related speaker ID (UUID).
 * @param file New speaker image.
 */
export const updateSpeakerImage = (id, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return axios().put("/prise/speaker-image/" + id, formData);
};

/**
 * Delete speaker by ID.
 * @param id Speaker ID (UUID).
 */
export const deleteSpeakerById = id => {
  return axios().delete("prise/delete-speaker/" + id);
};

/**
 * Get prise picture entire URL.
 * @param pictureKey Prise picture key taken from API response.
 */
export const getSpeakerImage = pictureKey => {
  return pictureKey
    ? "https://art-prise.s3.eu-west-3.amazonaws.com/" + pictureKey
    : require("@/assets/default-avatar.png");
};

export default {
  recentPrises,
  getOne,
  priseParticipationValidation,
  update,
  postNew,
  updateImage,
  getPrisePicture,
  newSpeaker,
  updateSpeaker,
  updateSpeakerImage,
  deleteSpeakerById,
  getSpeakerImage
};
